<template lang="pug">
  div
    el-alert(
      title="Bu bölümü görüntüleme yetkiniz yok"
      v-if="!can('product.categories.list')"
      :closable="false"
      type="error"
      show-icon
      center
    )
    el-card(body-style="padding:0")
      div(slot="header")
        el-form(inline)
          el-form-item
            el-button(
              type="primary"
              @click="create"
              icon="el-icon-plus"
              :disabled="!can('product.categories.create')"
            ) Oluştur
      el-table(
        :data="categories"
        style="width: 100%"
        v-loading="fetching"
        @row-dblclick="edit"
        :height="$store.state.windowHeight-154"
        size="mini"
        v-if="can('product.categories.list')"
      )
        el-table-column(type="index" width="30px" class-name="text-muted" align="right")
        el-table-column(prop="name" label="Kategori" sortable)
        el-table-column(prop="parent.name" label="Ana kategori" sortable v-if="hasFeature('sub.categories')")
        el-table-column(prop="id" label="İşlem" align="center")
          template(v-slot="props")
            el-button-group
              el-button.micro(plain @click="edit(props.row)"): span.el-icon-edit
              el-button.micro(plain @click="remove(props.row.id)"): span.el-icon-delete.text-danger

    el-drawer(:visible.sync="modal" size="400px" :title="category.name")
      el-form.p-10
        el-form-item
          el-input(v-model="category.name" clearable placeholder="Kategori")
        el-form-item(v-if="hasFeature('sub.categories')")
          el-select(v-model="category.parent_id" filterable clearable style="width: 100%" placeholder="Ana kategori seçiniz")
            el-option(v-for="c in categories.filter(c => c.id !== category.id)" :key="c.id" :value="c.id" :label="c.name")
        el-form-item
          center
            el-button(type="primary" icon="el-icon-check" :loading="saving" @click="save") Kaydet
</template>
<script>
import ProductCategory from '@/models/ProductCategory'

export default {
  name: 'agencies',
  data() {
    return {
      fetching: false,
      saving: false,
      modal: false,
      category: new ProductCategory({ name: '' }),
      categories: []
    }
  },
  async created() {
    if(this.can('product.categories.list')){
      await this.fetch()
    }
  },
  methods: {
    async fetch() {
      try {
        this.fetching = true
        this.categories = await ProductCategory.include('parent').get()
        this.fetching = false
      } catch ({ response }) {
        this.$message.error(response.data.message)
        this.fetching = false
      }
    },
    create() {
      this.category = new ProductCategory({ type: 'agency', name: '' })
      this.modal = true
    },
    async edit({ id }) {
      this.category = await ProductCategory.find(id)
      this.modal = true
    },
    async save() {
      try {
        this.saving = true
        await this.category.save()
        this.saving = false
        this.modal = false
        await this.fetch()
      } catch({ response }) {
        this.$message.error(response.data.message)
        this.saving = false
      }
    },
    async remove(id) {
      await this.$confirm('Kayıt silinmesine onay veriniz', 'Uyarı', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Vazgeç',
        type: 'warning',
        center: true
      })
      try {
        let { data } = await window.axios.delete('/product-categories/' + id)
        await this.fetch(false)
        if (data.deleted) {
          this.$message({
            type: 'success',
            message: 'Kayıt silinmiştir',
            center: true,
            closable: true
          })
        }
      } catch(e) {
        this.$message({
          type: 'error',
          message: 'Üzerine satış, rezervasyon veya ödeme tanımlanmıştır. Kayıt silinemez',
          center: true,
          closable: false
        })
      }
    },
  }
}
</script>
